
import { Options, Vue } from "vue-class-component";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import { DocumentEmployee } from "@/types/Document";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Filter from "@/components/Filter.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { notify } from "@/services/helpers";
import ProfileImage from "@/views/employees/ProfileImage.vue";

@Options({
  name: "DocumentEmployees",
  components: {
    ProfileImage,
    Breadcrumb,
    Filter,
    PaginationComponent,
    ConfirmationModal,
  },
})
export default class DocumentEmployees extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  employees: DocumentEmployee[] = [];
  notSingnedEmployees: DocumentEmployee[] = [];
  page = 0;
  notSingnedPage = 0;
  perPage = 15;
  notSingnedPerPage = 15;
  totalCount = 0;
  notSingnedtotalCount = 0;
  currentPage = 0;
  notSingnedCurrentPage = 0;
  crumbs: any = ["Dashboard", "Manage Documents", "Document Employees"];
  $refs!: {
    confirmDialogue: HTMLFormElement;
    dropDown: HTMLFormElement;
    filter: HTMLFormElement;
  };
  filterData = [
    {
      key: "isSigned",
      keyLabel: "Signed Status",
      dataType: "dropDowns",
      dropDownOptions: [
        {
          value: true,
          label: "Signed",
        },
        {
          value: false,
          label: "Not Signed",
        },
      ],
    },
  ];
  totalFilterCount = 0;
  paramQuery = "";
  pageTitle: any;

  hasProfileImage(employee: any) {
    if (employee.profileImage) {
      return employee.profileImage;
    }
    return "";
  }

  async initialize() {
    const documentId = this.$route.params.id;
    const departmentId = this.$route.query.departmentId;
    if (departmentId?.valueOf() == 0) {
      this.paramQuery = `DocumentId=${documentId}`;
    } else {
      this.paramQuery = `DocumentId=${documentId}&DepartmentId=${departmentId}`;
    }

    await this.workForceService
      .get<DocumentEmployee[]>(
        `/employeedocuments/employees?PageIndex=${this.page}&PageSize=${this.perPage}&${this.paramQuery}&isSigned=true`,
        false
      )
      .then((response: any) => {
        this.totalCount = response.content.totalCount;
        this.employees = response.content.items;
      });

    await this.workForceService
      .get<DocumentEmployee[]>(
        `/employeedocuments/employees?PageIndex=${this.notSingnedPage}&PageSize=${this.notSingnedPerPage}&${this.paramQuery}&isSigned=false`,
        false
      )
      .then((response: any) => {
        this.notSingnedEmployees = response.content.items;
        this.notSingnedtotalCount = response.content.items;
      });
  }

  async created() {
    this.pageTitle = this.$route.query.documentName;
    await this.initialize();
  }

  async clearFilter() {
    await this.initialize();
  }

  // For Filter
  filtered = false;
  savedFilterQuery = null;
  savedSort = null;
  async filter(query: any) {
    const result = this.extractKeyValuePairs(query);
    const isSigned = result.isSigned === "true";
    const documentId = this.$route.params.id;
    const departmentId = this.$route.query.departmentId;
    if (departmentId?.valueOf() == 0) {
      this.paramQuery = `DocumentId=${documentId}`;
    } else {
      this.paramQuery = `DocumentId=${documentId}&DepartmentId=${departmentId}`;
    }
    // if any filter is already applied when paginating
    this.savedFilterQuery = query;
    this.filtered = false;
    return this.workForceService
      .get<DocumentEmployee[]>(
        `/employeedocuments/employees?PageIndex=${this.page}&PageSize=${this.perPage}&${this.paramQuery}&${query}`,
        false
      )
      .then((response: any) => {
        if (!isSigned) {
          this.employees = [];
          this.totalCount = 0;
          this.notSingnedEmployees = response.content.items
            .filter((data: DocumentEmployee) => data.isSigned == false)
            .slice(0, this.notSingnedPerPage);
          this.notSingnedtotalCount = response.content.items.filter(
            (data: DocumentEmployee) => data.isSigned == false
          ).length;
        } else {
          this.employees = response.content.items;
          this.totalCount = response.content.totalCount;
          this.notSingnedEmployees = [];
          this.notSingnedtotalCount = 0;
        }
        this.totalFilterCount = response.content.totalCount;
        this.filtered = true;
      });
  }

  // For Pagination
  savedPaginationQuery = "";
  savedNotSignedPaginationQuery = "";
  get totalPages() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  get notSignedtotalPages() {
    return Math.ceil(this.notSingnedtotalCount / this.notSingnedPerPage);
  }

  goToPage(page: number) {
    const documentId = this.$route.params.id;
    const departmentId = this.$route.query.departmentId;
    if (departmentId?.valueOf() == 0) {
      this.paramQuery = `DocumentId=${documentId}`;
    } else {
      this.paramQuery = `DocumentId=${documentId}&DepartmentId=${departmentId}`;
    }
    this.currentPage = page;
    this.savedPaginationQuery = `PageIndex=${page}&PageSize=${this.perPage}&${this.paramQuery}`;
    return this.workForceService
      .get<DocumentEmployee[]>(
        `/employeedocuments/employees?${
          this.savedFilterQuery ? this.savedFilterQuery : ""
        }PageIndex=${page}&PageSize=${
          this.perPage
        }&DocumentId=${documentId}&isSigned=true`,
        false
      )
      .then((response: any) => {
        return (this.employees = response.content.items);
      });
  }

  goToNotSignedPage(page: number) {
    const documentId = this.$route.params.id;
    const departmentId = this.$route.query.departmentId;
    if (departmentId?.valueOf() == 0) {
      this.paramQuery = `DocumentId=${documentId}`;
    } else {
      this.paramQuery = `DocumentId=${documentId}&DepartmentId=${departmentId}`;
    }
    this.notSingnedCurrentPage = page;
    this.savedNotSignedPaginationQuery = `PageIndex=${page}&PageSize=${this.notSingnedPerPage}&${this.paramQuery}`;
    return this.workForceService
      .get<DocumentEmployee[]>(
        `/employeedocuments/employees?${
          this.savedFilterQuery ? this.savedFilterQuery : ""
        }PageIndex=${page}&PageSize=${
          this.notSingnedPerPage
        }&DocumentId=${documentId}&isSigned=false`,
        false
      )
      .then((response: any) => {
        return (this.notSingnedEmployees = response.content.items); //response.content.items.filter((data: DocumentEmployee) => data.isSigned == false));
      });
  }

  async sendDocumentEmail(workEmail: string) {
    const modal = await this.$refs.confirmDialogue.show({
      title: "Resend Confirmation?",
      message: "Are you sure you want to resend confirmation email?",
      okButton: "Ok",
      cancelButton: "Cancel",
      theme: "primary",
      type: "confirmation",
    });
    if (modal) {
      this.workForceService
        .get<void>(`/employeedocuments/sendEmail?workEmail=${workEmail}`, false)
        .then((response) => {
          if (!response.isError) {
            notify("Document mail sent successfully.", "Success", "success");
          } else {
            notify(response.errors, "Error", "danger");
          }
        });
    } else {
      return false;
    }
  }

  extractKeyValuePairs(queryString: string): Record<string, string> {
    const pairs = queryString.split("&");

    const values = pairs.reduce((obj: any, pair) => {
      const [key, value] = pair.split("=");
      obj[key] = value;
      return obj;
    }, {});

    return values;
  }
}
